import React, { useState, useMemo, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import GalleryGrid from '../components/GalleryGrid'
import { H1 } from '../components/Typography'

const PageContainer = styled.div`
  padding: var(--page-padding-top) 0;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - var(--header-height));
  
  @media (max-width: 768px) {
    padding: var(--page-padding-top-mobile) 0;
  }
`;

const GalleryContainer = styled.div`
  width: 100%;
  max-width: min(600px, 100%);
  margin: 0 auto;
  padding: var(--spacing-lg) var(--content-padding);
  position: relative;
  z-index: 20;
  
  @media (max-width: 768px) {
    padding: var(--spacing-md) var(--spacing-sm);
  }
`;

const CategoryFilter = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 2rem 0;
  flex-wrap: wrap;
  padding: 0 var(--spacing-sm);
`;

const FilterButton = styled(motion.button)`
  background: ${props => props.$isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  border: 1px solid rgba(255, 255, 255, ${props => props.$isActive ? '0.2' : '0.1'});
  color: ${props => props.$isActive ? 'var(--color-primary)' : 'var(--color-text)'};
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-md);
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: var(--font-size-sm);
  
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
  }
  
  &:active {
    transform: scale(0.98);
  }
`;

const StyledH1 = styled(H1)`
  margin-bottom: var(--spacing-lg);
  
  @media (max-width: 768px) {
    margin-bottom: var(--spacing-md);
  }
`;

const GridContainer = styled(motion.div)`
  width: 100%;
`;

const gridVariants = {
  initial: { 
    opacity: 0,
    y: 20
  },
  animate: { 
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: "easeOut"
    }
  },
  exit: { 
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2
    }
  }
};

const Gallery = ({ setHideHeader }) => {
  const [activeCategory, setActiveCategory] = useState('tattoo');
  
  const categories = [
    { id: 'tattoo', label: 'Tetovaže' },
    { id: 'events', label: 'Dogodki' }
  ];

  const galleryContent = [
    {
      src: '/Images/Gallery/IMG_2042-min-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_2042-min-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_2045-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_2045-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3047-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3047-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3188-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3188-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3196-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3196-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3197-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3197-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3198-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3198-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3257-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3257-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3258-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3258-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3259-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3259-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3580-min-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3580-min-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3581-min-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3581-min-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3589-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3589-min.jpeg',
      alt: 'Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_3820-min-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_3820-min-min.jpeg',
      alt: 'Tattoo design',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_4961-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_4961-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9085-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9085-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9135-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9135-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9308-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9308-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9309-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9309-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9376-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9376-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9377-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9377-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      src: '/Images/Gallery/IMG_9418-min.jpeg',
      fullSrc: '/Images/Gallery/IMG_9418-min.jpeg',
      alt: 'Mat Cap Tattoo artwork',
      category: 'tattoo'
    },
    {
      videoUrl: '/Images/Gallery/1.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3820-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/2.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3821-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/3.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3822-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/4.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3823-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/5.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3825-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/6.mp4',
      thumbnailUrl: '/Images/VideoThumbnails/IMG-3826-min.PNG',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    },
    {
      videoUrl: '/Images/Gallery/7.mp4',
      type: 'local',
      alt: 'Mat Cap Tattoo dogodek',
      category: 'events'
    }
  ];

  const filteredContent = useMemo(() => {
    console.log('Filtering content:', {
      activeCategory,
      allContent: galleryContent,
      videoItems: galleryContent.filter(item => item.videoUrl),
      filteredItems: galleryContent.filter(item => item.category === activeCategory)
    });
    return galleryContent.filter(item => item.category === activeCategory);
  }, [activeCategory]);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('Gallery content:', {
      activeCategory,
      totalItems: galleryContent.length,
      filteredItems: filteredContent.length,
      videos: galleryContent.filter(item => item.videoUrl).length
    });
  }, [activeCategory, filteredContent]);

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
    >
      <PageContainer>
        <GalleryContainer>
          <StyledH1 $align="center">Galerija del</StyledH1>
          
          <CategoryFilter>
            {categories.map(category => (
              <FilterButton
                key={category.id}
                $isActive={activeCategory === category.id}
                onClick={() => setActiveCategory(category.id)}
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                {category.label}
              </FilterButton>
            ))}
          </CategoryFilter>

          <AnimatePresence mode="wait">
            <GridContainer
              key={activeCategory}
              variants={gridVariants}
              initial="initial"
              animate="animate"
              exit="exit"
            >
              <GalleryGrid 
                images={filteredContent} 
                setHideHeader={setHideHeader}
                onDebug={(data) => console.log('GalleryGrid Debug:', data)}
              />
            </GridContainer>
          </AnimatePresence>
        </GalleryContainer>
      </PageContainer>
    </motion.div>
  );
};

export default Gallery;
