export async function sendBookingEmail(formData) {
  try {
    console.log('Starting email request with data:', {
      email: formData.personalInfo.email,
      name: formData.personalInfo.fullName,
      details: formData.tattooDetails
    });
    
    const functionUrl = import.meta.env.DEV 
      ? 'http://localhost:5001/mat-cap-website/us-central1/api'
      : 'https://us-central1-mat-cap-website.cloudfunctions.net/api';
    
    console.log('Using endpoint:', functionUrl);
    
    const emailData = {
      formData
    };
    
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(emailData)
    });

    console.log('Response status:', response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server error details:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText
      });
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
    }

    const data = await response.json();
    console.log('Success response:', data);
    return { success: true, data };
  } catch (error) {
    console.error('Email sending failed:', error);
    return { success: false, error: error.message };
  }
}
