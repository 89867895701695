import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledButton = styled(motion.button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: ${props => props.$size === 'small' ? '0.4rem 0.8rem' : '0.8rem 1.6rem'};
  background: ${props => props.$variant === 'outline' 
    ? 'transparent' 
    : 'var(--color-primary)'};
  border: 1px solid ${props => props.$variant === 'outline' 
    ? 'var(--color-primary)' 
    : 'transparent'};
  border-radius: 6px;
  color: ${props => props.$variant === 'outline' 
    ? 'var(--color-text)' 
    : 'var(--color-background)'};
  font-size: ${props => props.$size === 'small' ? '0.8125rem' : '0.875rem'};
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  width: ${props => props.fullWidth ? '100%' : 'auto'};
  box-shadow: ${props => props.$variant === 'outline' 
    ? 'none' 
    : '0 2px 4px rgba(0, 0, 0, 0.1)'};
  position: relative;
  overflow: hidden;
  z-index: 1;

  /* Subtle gradient background */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${props => props.$variant === 'outline' 
      ? 'linear-gradient(120deg, transparent 0%, rgba(255, 215, 0, 0.03) 50%, transparent 100%)'
      : 'linear-gradient(120deg, transparent 0%, rgba(255, 255, 255, 0.05) 50%, transparent 100%)'};
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: -1;
  }

  /* Shine effect */
  .shine {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(
      45deg,
      transparent 45%,
      rgba(255, 255, 255, 0.1) 50%,
      transparent 55%
    );
    transform: translateX(-100%) rotate(45deg);
    transition: transform 0.3s cubic-bezier(0.1, 0.7, 0.6, 1);
  }

  /* Interactive border effect */
  &::after {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(120deg, 
      var(--color-primary),
      var(--color-secondary),
      var(--color-primary)
    );
    border-radius: 7px;
    z-index: -2;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: ${props => props.$variant === 'outline' 
      ? '0 1px 3px rgba(0, 0, 0, 0.05)' 
      : '0 4px 8px rgba(0, 0, 0, 0.12)'};

    &::before {
      opacity: 1;
    }

    &::after {
      opacity: ${props => props.$variant === 'outline' ? '0.1' : '0.2'};
    }

    .shine {
      transform: translateX(100%) rotate(45deg);
    }
  }

  &:active {
    transform: translateY(0);
    box-shadow: ${props => props.$variant === 'outline' 
      ? 'none' 
      : '0 1px 2px rgba(0, 0, 0, 0.15)'};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
    box-shadow: none;
  }

  .text-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transform-style: preserve-3d;
    transition: transform 0.2s ease;
  }
`;

const Button = ({ 
  children, 
  $variant = 'primary', 
  size = 'medium',
  fullWidth = false,
  ...props 
}) => {
  return (
    <StyledButton
      $variant={$variant}
      $size={size}
      fullWidth={fullWidth}
      whileHover={{ 
        scale: 1.01,
        transition: { duration: 0.2, ease: [0.1, 0.7, 0.6, 1] }
      }}
      whileTap={{ 
        scale: 0.98,
        transition: { duration: 0.1, ease: [0.4, 0, 0.2, 1] }
      }}
      initial={{ opacity: 0, y: 5 }}
      animate={{ 
        opacity: 1, 
        y: 0,
        transition: { duration: 0.2, ease: [0.1, 0.7, 0.6, 1] }
      }}
      {...props}
    >
      <span className="shine" />
      <span className="text-container">
        {children}
      </span>
    </StyledButton>
  );
};

export default Button;