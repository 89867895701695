import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Lightbox from './Lightbox';
import VideoThumbnail from './VideoThumbnail';
import { FaPlay } from 'react-icons/fa';

const Grid = styled.div`
  display: grid;
  width: 100%;
  padding: 2rem 0;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(140px, 1fr));
  
  @media (min-width: 480px) {
    grid-template-columns: repeat(2, minmax(180px, 1fr));
  }
  
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    gap: 1.5rem;
  }
`;

const ImageContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  filter: grayscale(100%);

  ${ImageContainer}:hover & {
    filter: grayscale(0%);
  }
`;

const PlayOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.8;
  transition: all 0.3s ease;

  ${ImageContainer}:hover & {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
  }
`;

const PlayButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.8);
  transform: scale(0.9);

  svg {
    color: white;
    font-size: 24px;
    margin-left: 4px; // Slight offset for visual center
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
  }

  ${ImageContainer}:hover & {
    transform: scale(1);
    background: rgba(255, 255, 255, 0.25);
  }
`;

const GalleryGrid = ({ images, setHideHeader, onDebug }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(new Set());

  const handleImageLoad = useCallback((index) => {
    setImagesLoaded(prev => new Set([...prev, index]));
  }, []);

  const handleItemClick = useCallback((item, index) => {
    setCurrentIndex(index);
    setIsLightboxOpen(true);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            if (img.dataset.src) {
              img.src = img.dataset.src;
              observer.unobserve(img);
            }
          }
        });
      },
      {
        rootMargin: '50px',
        threshold: 0.1
      }
    );

    const images = document.querySelectorAll('[data-src]');
    images.forEach(img => observer.observe(img));

    return () => observer.disconnect();
  }, [images]);

  useEffect(() => {
    console.log('GalleryGrid mounted with images:', {
      totalImages: images.length,
      videoItems: images.filter(item => item.videoUrl),
      imageItems: images.filter(item => !item.videoUrl)
    });
  }, [images]);

  return (
    <>
      <Grid>
        {images.map((image, index) => {
          const isVideo = Boolean(image.videoUrl);
          console.log('Rendering item:', { index, isVideo, image });

          return (
            <ImageContainer
              key={`${image.videoUrl || image.src}-${index}`}
              onClick={() => handleItemClick(image, index)}
              $isVideo={isVideo}
            >
              {isVideo ? (
                <>
                  <Image
                    src={image.thumbnailUrl}
                    alt={image.alt}
                    onLoad={() => handleImageLoad(index)}
                    loading="lazy"
                  />
                  <PlayOverlay>
                    <PlayButton>
                      <FaPlay />
                    </PlayButton>
                  </PlayOverlay>
                </>
              ) : (
                <Image
                  src={image.src}
                  alt={image.alt}
                  onLoad={() => handleImageLoad(index)}
                  loading="lazy"
                />
              )}
            </ImageContainer>
          );
        })}
      </Grid>

      <Lightbox 
        isOpen={isLightboxOpen}
        onClose={() => setIsLightboxOpen(false)}
        images={images}
        currentIndex={currentIndex}
        onNext={() => setCurrentIndex(prev => (prev + 1) % images.length)}
        onPrevious={() => setCurrentIndex(prev => (prev - 1 + images.length) % images.length)}
        setHideHeader={setHideHeader}
      />
    </>
  );
};

export default React.memo(GalleryGrid);
