import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import Hero from '../components/Hero';
import { pageTransition } from '../styles/GlobalStyles';
import { Helmet } from 'react-helmet-async';

const HomeContainer = styled(motion.div)`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 1rem;
`;

const Home = () => {
  React.useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => window.removeEventListener('resize', setVH);
  }, []);

  return (
    <>
      <Helmet>
        <title>Mat Cap Tattoo | Profesionalni Tattoo Studio v Kamniku</title>
        <meta name="description" content="Profesionalni tattoo studio v Kamniku. Specializirani za custom tetovaže, realistične tetovaže in cover-up projekte." />
        <meta property="og:title" content="Mat Cap Tattoo Studio" />
        <meta property="og:description" content="Profesionalni tattoo studio v Kamniku" />
        <meta property="og:image" content="/Images/og-image.jpg" />
      </Helmet>
      <HomeContainer {...pageTransition} className="container">
        <Hero removeBackground={true} />
      </HomeContainer>
    </>
  );
};

export default Home;
