import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useAuth } from '../../contexts/AuthContext';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { H1, H2, H3 } from '../../components/Typography';
import { ArrowLeft, Save, Archive, Trash2 } from 'react-feather';
import { AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Container = styled.div`
  width: 100%;
  max-width: var(--admin-max-width);
  margin: 0 auto;
  padding: 0 1rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const BackButton = styled(motion.button)`
  background: transparent;
  border: none;
  color: var(--color-text);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 8px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const Section = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: var(--font-size-lg);
  margin-bottom: 1rem;
`;

const Field = styled.div`
  margin-bottom: 1rem;

  label {
    display: block;
    font-size: var(--font-size-sm);
    margin-bottom: 0.5rem;
    color: rgba(255, 255, 255, 0.6);
  }

  p {
    font-size: var(--font-size-base);
  }
`;

const StatusSelect = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;

const NotesTextarea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  font-family: inherit;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;

const SaveButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: var(--color-primary);
  border: none;
  border-radius: 8px;
  color: black;
  font-weight: 500;
  cursor: pointer;
  margin-left: auto;
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
`;

const ImageContainer = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: zoom-in;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
  cursor: zoom-out;

  img {
    max-width: 90vw;
    max-height: 90vh;
    object-fit: contain;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
`;

const ActionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: ${props => props.$variant === 'delete' ? 'rgba(255, 59, 48, 0.1)' : 'rgba(255, 255, 255, 0.05)'};
  border: 1px solid ${props => props.$variant === 'delete' ? 'rgba(255, 59, 48, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
  color: ${props => props.$variant === 'delete' ? '#ff3b30' : 'var(--color-text)'};
  border-radius: 8px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.$variant === 'delete' ? 'rgba(255, 59, 48, 0.2)' : 'rgba(255, 255, 255, 0.1)'};
    transform: translateY(-1px);
  }
`;

const SchedulingSection = styled.div`
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: ${props => props.$show ? 'block' : 'none'};
`;

const DateTimeInputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--color-text);
  font-size: var(--font-size-sm);

  &:focus {
    outline: none;
    border-color: var(--color-primary);
    background: rgba(255, 255, 255, 0.07);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--color-text);
  font-size: var(--font-size-sm);

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  min-height: 100px;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: var(--color-text);
  font-size: var(--font-size-sm);
  font-family: inherit;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: var(--color-primary);
  }
`;

const OrderDetails = () => {
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [order, setOrder] = useState(null);
  const [status, setStatus] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        if (!auth?.currentUser) {
          console.log('Waiting for auth...');
          return;
        }

        const docRef = doc(db, 'orders', orderId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          throw new Error('Order not found');
        }

        const data = docSnap.data();
        console.log('Fetched order:', data);
        setOrder(data);
        setStatus(data.status || 'new');
        setNotes(data.notes || '');
      } catch (error) {
        console.error('Error fetching order:', error);
      } finally {
        setLoading(false);
      }
    };

    if (auth?.currentUser) {
      fetchOrder();
    }
  }, [orderId, auth?.currentUser]);

  const handleSave = async () => {
    try {
      if (!auth?.currentUser) {
        throw new Error('Not authenticated');
      }

      const docRef = doc(db, 'orders', orderId);
      await updateDoc(docRef, {
        status,
        notes,
        scheduling: order.scheduling,
        updated_at: new Date().toISOString()
      });

      // Send confirmation email if status changed to scheduled
      if (status === 'scheduled' && order.scheduling?.date && order.scheduling?.time) {
        try {
          const response = await fetch('http://localhost:5001/mat-cap-website/us-central1/api/schedule-confirmation', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
              order: {
                ...order,
                id: orderId
              }
            })
          });

          if (!response.ok) {
            const errorData = await response.text();
            throw new Error(`Failed to send confirmation email: ${errorData}`);
          }

          toast.success('Termin potrjen in email poslan', {
            style: {
              background: '#333',
              color: '#fff',
            }
          });
        } catch (emailError) {
          console.error('Error sending confirmation email:', emailError);
          toast.error('Termin potrjen, vendar email ni bil poslan');
        }
      } else {
        toast.success('Spremembe shranjene');
      }
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Napaka pri shranjevanju');
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this order? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'orders', orderId));
        toast.success('Order deleted successfully');
        navigate('/admin/orders');
      } catch (error) {
        console.error('Error deleting order:', error);
        toast.error('Failed to delete order');
      }
    }
  };

  const handleArchive = async () => {
    try {
      const docRef = doc(db, 'orders', orderId);
      await updateDoc(docRef, {
        status: 'archived',
        archived_at: new Date().toISOString()
      });
      toast.success('Order archived successfully');
      navigate('/admin/orders');
    } catch (error) {
      console.error('Error archiving order:', error);
      toast.error('Failed to archive order');
    }
  };

  const handleSchedulingChange = (field, value) => {
    setOrder(prev => ({
      ...prev,
      scheduling: {
        ...prev.scheduling,
        [field]: value
      }
    }));
  };

  const parseDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!order) {
    return <div>Order not found</div>;
  }

  // Destructure the parsed data
  const { personal_info, tattoo_details, tattoo_idea } = order;

  return (
    <Container>
      <Header>
        <BackButton
          onClick={() => navigate('/admin/orders')}
          whileHover={{ x: -5 }}
          whileTap={{ scale: 0.95 }}
        >
          <ArrowLeft size={20} />
          Back to Orders
        </BackButton>
      </Header>

      <H1>{personal_info?.fullName || 'No Name'}</H1>

      <Section>
        <SectionTitle>Personal Information</SectionTitle>
        <Field>
          <label>Email</label>
          <p>{personal_info?.email || 'N/A'}</p>
        </Field>
        <Field>
          <label>Phone</label>
          <p>{personal_info?.phone || 'N/A'}</p>
        </Field>
        <Field>
          <label>Birth Date</label>
          <p>{personal_info?.birthDate || 'N/A'}</p>
        </Field>
      </Section>

      <Section>
        <SectionTitle>Tattoo Details</SectionTitle>
        <Field>
          <label>Size</label>
          <p>{tattoo_details?.size || 'N/A'}</p>
        </Field>
        <Field>
          <label>Location</label>
          <p>{tattoo_details?.location || 'N/A'}</p>
        </Field>
        <Field>
          <label>Style</label>
          <p>{tattoo_details?.style || 'N/A'}</p>
        </Field>
        <Field>
          <label>First Tattoo</label>
          <p>{tattoo_details?.isFirstTattoo === 'yes' ? 'Yes' : 'No'}</p>
        </Field>
        {tattoo_details?.locationImage && (
          <Field>
            <label>Location Image</label>
            <ImageGrid>
              <ImageContainer>
                <Image 
                  src={tattoo_details.locationImage} 
                  alt="Tattoo location"
                  onClick={() => handleImageClick(tattoo_details.locationImage)}
                  loading="lazy"
                />
              </ImageContainer>
            </ImageGrid>
          </Field>
        )}
      </Section>

      <Section>
        <SectionTitle>Tattoo Idea</SectionTitle>
        <Field>
          <label>Description</label>
          <p>{tattoo_idea?.description || 'N/A'}</p>
        </Field>
        {tattoo_idea?.referenceImages?.length > 0 && (
          <Field>
            <label>Reference Images</label>
            <ImageGrid>
              {tattoo_idea.referenceImages.map((image, index) => (
                <ImageContainer key={index}>
                  <Image 
                    src={image} 
                    alt={`Reference ${index + 1}`}
                    onClick={() => handleImageClick(image)}
                    loading="lazy"
                  />
                </ImageContainer>
              ))}
            </ImageGrid>
          </Field>
        )}
      </Section>

      <Section>
        <SectionTitle>Order Management</SectionTitle>
        <Field>
          <label>Status</label>
          <StatusSelect 
            value={status} 
            onChange={(e) => {
              setStatus(e.target.value);
              if (e.target.value === 'scheduled' && !order.scheduling) {
                setOrder(prev => ({
                  ...prev,
                  scheduling: {
                    date: null,
                    time: '',
                    duration: '2',
                    notes: ''
                  }
                }));
              }
            }}
          >
            <option value="new">New</option>
            <option value="contacted">Contacted</option>
            <option value="scheduled">Scheduled</option>
            <option value="completed">Completed</option>
            <option value="archived">Archived</option>
            <option value="cancelled">Cancelled</option>
          </StatusSelect>
        </Field>
        <Field>
          <label>Notes</label>
          <NotesTextarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Add notes about this order..."
          />
        </Field>
        <SaveButton
          onClick={handleSave}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <Save size={16} />
          Save Changes
        </SaveButton>
      </Section>

      <SchedulingSection $show={status === 'scheduled'}>
        <H3>Scheduling Details</H3>
        <DateTimeInputs>
          <Field>
            <label>Date</label>
            <DatePicker
              selected={parseDate(order.scheduling?.date)}
              onChange={date => handleSchedulingChange('date', date?.toISOString())}
              dateFormat="dd/MM/yyyy"
              minDate={new Date()}
              placeholderText="Select date"
              customInput={<Input />}
            />
          </Field>
          <Field>
            <label>Time</label>
            <Select
              value={order.scheduling?.time || ''}
              onChange={e => handleSchedulingChange('time', e.target.value)}
            >
              <option value="">Select time</option>
              <option value="09:00">09:00</option>
              <option value="10:00">10:00</option>
              <option value="11:00">11:00</option>
              <option value="12:00">12:00</option>
              <option value="13:00">13:00</option>
              <option value="14:00">14:00</option>
              <option value="15:00">15:00</option>
              <option value="16:00">16:00</option>
            </Select>
          </Field>
        </DateTimeInputs>
        <Field>
          <label>Estimated Duration (hours)</label>
          <Select
            value={order.scheduling?.duration || '2'}
            onChange={e => handleSchedulingChange('duration', e.target.value)}
          >
            <option value="1">1 hour</option>
            <option value="2">2 hours</option>
            <option value="3">3 hours</option>
            <option value="4">4 hours</option>
            <option value="5">5 hours</option>
            <option value="6">6 hours</option>
            <option value="7">7 hours</option>
            <option value="8">8 hours</option>
          </Select>
        </Field>
        <Field>
          <label>Scheduling Notes</label>
          <TextArea
            value={order.scheduling?.notes || ''}
            onChange={e => handleSchedulingChange('notes', e.target.value)}
            placeholder="Add any notes about the scheduling..."
          />
        </Field>
      </SchedulingSection>

      <ActionButtons>
        <ActionButton
          onClick={handleArchive}
          whileHover={{ y: -2 }}
          whileTap={{ scale: 0.98 }}
        >
          <Archive size={16} />
          Archive Order
        </ActionButton>
        
        <ActionButton
          $variant="delete"
          onClick={handleDelete}
          whileHover={{ y: -2 }}
          whileTap={{ scale: 0.98 }}
        >
          <Trash2 size={16} />
          Delete Order
        </ActionButton>
      </ActionButtons>

      {/* Image Modal */}
      <AnimatePresence>
        {selectedImage && (
          <ImageModal
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeImageModal}
          >
            <motion.img
              src={selectedImage}
              alt="Full size image"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.8 }}
              onClick={(e) => e.stopPropagation()}
            />
          </ImageModal>
        )}
      </AnimatePresence>
    </Container>
  );
};

export default OrderDetails;
