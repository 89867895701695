import React from 'react';
import styled from 'styled-components';

const ThumbnailContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(20, 20, 20, 0.95) 0%,
    rgba(30, 30, 30, 0.95) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;

  /* Add subtle animated gradient */
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      -45deg,
      transparent 0%,
      rgba(255, 215, 0, 0.05) 50%,
      transparent 100%
    );
    background-size: 200% 200%;
    animation: gradientMove 3s ease infinite;
  }

  /* Play button */
  &::after {
    content: '▶';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  @keyframes gradientMove {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
`;

const VideoThumbnail = () => {
  console.log('Rendering VideoThumbnail');
  return <ThumbnailContainer />;
};

export default VideoThumbnail; 