import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from './firebase';
import imageCompression from 'browser-image-compression';

export async function uploadImage(file, type = 'reference') {
  try {
    // Verify storage configuration
    const bucketConfig = {
      bucket: storage.app.options.storageBucket,
      isInitialized: !!storage,
      type: type,
      folder: type === 'location' ? 'tattoo-locations' : 'tattoo-references'
    };
    console.log('Storage configuration:', bucketConfig);

    // Compress and upload
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };

    const compressedFile = await imageCompression(file, options);
    const folder = bucketConfig.folder;
    const extension = file.name.split('.').pop().toLowerCase();
    const path = `${folder}/${Date.now()}-${Math.random().toString(36).slice(2)}.${extension}`;
    
    console.log('Upload attempt:', {
      path,
      size: compressedFile.size,
      type: compressedFile.type
    });

    const storageRef = ref(storage, path);
    const snapshot = await uploadBytes(storageRef, compressedFile);
    const url = await getDownloadURL(snapshot.ref);

    console.log('Upload successful:', {
      url,
      path: snapshot.ref.fullPath
    });

    return { success: true, url };
  } catch (error) {
    console.error('Upload failed:', {
      error: error.message,
      code: error.code,
      name: error.name,
      storageError: error.customData?.serverResponse,
      bucket: storage.app.options.storageBucket
    });
    return { success: false, error: error.message };
  }
}

export async function deleteImage(path, type = 'reference') {
  try {
    const folder = type === 'location' ? 'tattoo-locations' : 'tattoo-references';
    const imageRef = ref(storage, `${folder}/${path}`);
    await deleteObject(imageRef);
    return { success: true };
  } catch (error) {
    console.error('Delete failed:', error);
    return { success: false, error: error.message };
  }
} 