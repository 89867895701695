import React, { useState, useMemo, Suspense } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import Layout from './components/Layout'
import Home from './pages/Home'
import About from './pages/About'
import Contact from './pages/Contact'
import Gallery from './pages/Gallery'
import Book from './pages/Book'
import FAQ from './pages/FAQ'
import GlobalStyles from './styles/GlobalStyles'
import MasonryBackground from './components/MasonryBackground'
import SocialMediaLinks from './components/SocialMediaLinks'
import { AuthProvider } from './contexts/AuthContext'
import Login from './pages/Login'
import ProtectedRoute from './components/auth/ProtectedRoute'
import DashboardLayout from './components/admin/DashboardLayout'
import Orders from './pages/admin/Orders'
import OrderDetails from './pages/admin/OrderDetails'
import PerformanceTracker from './components/PerformanceTracker'
import AdminRoute from './components/admin/AdminRoute'
import { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';

function App() {
  const location = useLocation()
  const [hideHeader, setHideHeader] = useState(false)
  
  const { isHomePage, isAdminPage } = useMemo(() => ({
    isHomePage: location.pathname === '/',
    isAdminPage: location.pathname.startsWith('/admin')
  }), [location.pathname])

  const mainRoutes = [
    { path: '/', element: <Home /> },
    { path: '/about', element: <About /> },
    { path: '/contact', element: <Contact /> },
    { path: '/gallery', element: <Gallery setHideHeader={setHideHeader} /> },
    { path: '/faq', element: <FAQ /> },
    { path: '/book', element: <Book /> },
    { path: '/login', element: <Login /> }
  ]

  const adminRoutes = [
    { path: '/admin/orders', element: <Orders /> },
    { path: '/admin/orders/:orderId', element: <OrderDetails /> }
  ]

  return (
    <>
      <Helmet>
        <html lang="sl" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://matcap.si" />
        <meta name="theme-color" content="#000000" />
        
        <title>Mat Cap Tattoo | Profesionalni Tattoo Studio Kamnik</title>
        <meta name="description" content="Mat Cap Tattoo studio v Kamniku. Profesionalne tetovaže po meri, custom dizajni in cover-up projekti. Ustvarjamo unikatne tetovaže po vaših željah." />
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://matcap.si" />
        <meta property="og:title" content="Mat Cap Tattoo Studio" />
        <meta property="og:description" content="Profesionalni tattoo studio v Kamniku. Ustvarjamo unikatne tetovaže po vaših željah." />
        <meta property="og:image" content="/Images/og-mat-cap-image-min.png" />
        
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://matcap.si" />
        <meta name="twitter:title" content="Mat Cap Tattoo Studio" />
        <meta name="twitter:description" content="Profesionalni tattoo studio v Kamniku. Ustvarjamo unikatne tetovaže po vaših željah." />
        <meta name="twitter:image" content="/Images/og-mat-cap-image-min.png" />
        <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
        <link rel="alternate icon" type="image/png" href="/favicon.png" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "TattooParl or",
            "name": "Mat Cap Tattoo",
            "image": "/Images/og-mat-cap-image-min.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Glavni trg 7",
              "addressLocality": "Kamnik",
              "postalCode": "1241",
              "addressCountry": "SI"
            },
            "url": "https://matcap.si",
            "telephone": "YOUR-PHONE",
            "email": "tattoo@matcap.si"
          })}
        </script>
      </Helmet>
      <GlobalStyles />
      <Toaster position="bottom-right" />
      <Suspense fallback={null}>
        {process.env.NODE_ENV === 'development' && <PerformanceTracker />}
        <AuthProvider>
          {!isAdminPage ? (
            <>
              {isHomePage && <MasonryBackground itemCount={20} />}
              <Layout 
                isHomePage={isHomePage} 
                hideHeader={hideHeader}
                isAdmin={isAdminPage}
              >
                <AnimatePresence mode="wait" initial={false}>
                  <Routes location={location} key={location.pathname}>
                    {mainRoutes.map(({ path, element }) => (
                      <Route key={path} path={path} element={element} />
                    ))}
                  </Routes>
                </AnimatePresence>
              </Layout>
              <SocialMediaLinks />
            </>
          ) : (
            <AdminRoute>
              <ProtectedRoute>
                <DashboardLayout>
                  <Routes location={location} key={location.pathname}>
                    {adminRoutes.map(({ path, element }) => (
                      <Route key={path} path={path} element={element} />
                    ))}
                  </Routes>
                </DashboardLayout>
              </ProtectedRoute>
            </AdminRoute>
          )}
        </AuthProvider>
      </Suspense>
    </>
  )
}

export default App
